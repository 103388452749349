import React from 'react'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'
import { strings } from '../static/strings'
import Footer from '@root/Footer'
import { useGTM } from '@hooks/useGTM'
import { replaceWithCorrectTranslation } from '../helpers/replaceWithCorrectTranslation'
import SEO from '../components/root/Seo'
import NavBar from '@root/NavBar'

export default function RacesTemplate({ data, path }) {
	const page = data.main
	const { locale } = page.locale
	const lang = locale.substr(0, 2)
	useGTM({ locale, path, pageType: page?.pageOptionsPageType?.pageType || 'saison' })

	const blocksJSON = JSON.parse(page.blocksJSON)
	return (
		<>
			<NavBar />
			<SEO seoData={page.seo} />
			{page.blocks.map((module, index) => {
				if (module.__typename.includes('WpCore')) return
				replaceWithCorrectTranslation(module, blocksJSON)
				const LoadableModuleBlock = loadable(() => import(`@modules/${module.__typename}`))
				return <LoadableModuleBlock data={module} key={`${module.__typename}-${index}`} locale={locale} overline={strings[lang].raceCalendar} />
			})}
			<Footer />
		</>
	)
}

export const query = graphql`
	query ($post: String) {
		main: wpRaces(id: { eq: $post }) {
			...PostRacesFragment
			blocks {
				__typename
				...AllBlocks
			}
			seo {
				metaDesc
				title
				opengraphImage {
					localFile {
						publicURL
					}
				}
				schema {
					pageType
				}
			}
			blocksJSON
			pageOptionsPageType {
				pageType
			}
		}
	}
`
